<template>
  <v-app>
    <v-overlay
      :model-value="true"
      persistent
      scrim="#000000"
      width="100%"
      class="align-center"
    >
      <v-row class="mb-4" justify=center no-gutters>
        <v-alert
          prominent
          color="white"
          max-width="fit-content"
          text="Вас интересует остекление?"
          elevation=4
        >
          <v-row class="mt-2" justify=center no-gutters>
            <div v-ripple.center @click="validateCaptcha" class="text-white bg-primary text-overline mr-3 styled-var">
              Да
            </div>
            <div v-ripple.center @click="validateCaptcha" class="text-white bg-primary text-overline styled-var">
              Нет
            </div>
          </v-row>
        </v-alert>
      </v-row>
      <vue-recaptcha
        ref="recaptcha"
        size="invisible"
        :sitekey="site_key"
        @verify="setAntibotKey"
        @expired="onCaptchaExpired"
        @error="captchaError"
      />
    </v-overlay>

    <v-img cover :src="imgUrl"></v-img>
  </v-app>
</template>
<script>
import { railsData } from './init_data/rails_data.js';
import axios from 'axios';

export default {
  name: 'AppAntibot',
  data() {
    return {
      main_domain,
      site_key: railsData.sitekey,
    }
  },
  methods: {
    onCaptchaExpired () {
      this.$refs.recaptcha.reset();
    },
    validateCaptcha() {
      this.$refs.recaptcha.execute();
    },
    setAntibotKey(recaptchaToken) {
      let params = {'g-recaptcha-response-data': recaptchaToken};
			axios
        .post('/api/calculator/set_ab_key',params)
				.then( res => {
          $cookies.set('Ab', recaptchaToken, '1y', '/', '.' + main_domain); //half year
          if (document.referrer) {
            let referrer = new URL(document.referrer).host;
            let url_to_go = new URL(location.href);
            url_to_go.searchParams.append('utm_referrer', referrer);
            url_to_go.searchParams.append('utm_source', referrer);
            window.location = url_to_go.toString();
          } else {
            window.location.reload();
          }
				})
				.catch( err => {
					console.error(err);
				})
    },
    captchaError(error) {
      console.error("grecaptcha error", error);
    },
  },
}
</script>
<script setup>

import { ref } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';

import { useDisplay } from 'vuetify';
const { mobile } = useDisplay();

import { getPublickImgURL } from './composables/getPublickImgUrl.js'

let url = `antibot_background${mobile.value ? '_mobile' : ''}.webp`;
const imgUrl = ref(getPublickImgURL(url));

</script>
<style type="text/css" lang="scss" media="screen">
.styled-var {
  cursor: pointer;
  border-radius: 3px;
  width: 60px;
  text-align: center;
}
</style>
